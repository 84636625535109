import store from "../store";
import {
  db,
  setDoc,
  doc,
  collection,
  where,
  query,
  getDocs,
  updateDoc,
  deleteDoc,
  increment,
} from "../main";
import { IItem } from "@/interface/interface";
import { deleteObject, getStorage, listAll, ref } from "firebase/storage";
import { limit, orderBy } from "firebase/firestore";

export default class ItemService {
  async uploadItem(item: IItem) {
    // const itemRef = doc(db, `items_${process.env.VUE_APP_ENV}`, item.id);
    // await setDoc(itemRef, item, { merge: true });
    await setDoc(doc(db, `items_${process.env.VUE_APP_ENV}`, item.id), {
      ...item,
    });
  }

  async getAllItems() {
    console.log(process.env.VUE_APP_ENV);
    const itemRef = collection(db, `items_${process.env.VUE_APP_ENV}`);
    const querySnapshot = await getDocs(itemRef);
    // TODO: Use querySnapshot.size to show check if there are any items
    const items = querySnapshot.docs
      .map((doc) => doc.data())
      .map((item) => {
        return {
          ...item,
        };
      });
    store.commit("SET_ALL_ITEMS", items);
    return items;
  }

  async getUserItems(id: string) {
    const itemRef = collection(db, `items_${process.env.VUE_APP_ENV}`);
    const q = query(itemRef, where("owner", "==", id));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      store.commit("SET_USER_ITEMS", []);
      return [];
    } else {
      const items = querySnapshot.docs.map((doc) => doc.data());
      store.commit("SET_USER_ITEMS", items);
      return items;
    }
  }

  async searchItems(searchString: string) {
    const itemsRef = collection(db, `items_${process.env.VUE_APP_ENV}`);
    const snapshot = await getDocs(itemsRef);
    const filteredItems = snapshot.docs.map((doc) => doc.data());
    const searchTerms = searchString.toLowerCase().split(/\s+/); // Split the search string into words by spaces

    return filteredItems.filter((item) => {
      const itemName = item.name.toLowerCase();
      // Check if any word in the searchString is contained in the item's name
      return (
        searchTerms.some((term) => itemName.includes(term)) ||
        item.description?.toLowerCase().includes(searchString)
      );
    });
  }

  async getItemByCategory(category: string) {
    const itemRef = collection(db, `items_${process.env.VUE_APP_ENV}`);
    const q = query(itemRef, where("category", "==", category));
    const querySnapshot = await getDocs(q);
    const items = querySnapshot.docs.map((doc) => doc.data());
    return items;
  }

  async getItemNameById(id: string) {
    const itemRef = collection(db, `items_${process.env.VUE_APP_ENV}`);
    const q = query(itemRef, where("id", "==", id));
    const querySnapshot = await getDocs(q);
    const item = querySnapshot.docs.map((doc) => doc.data())[0];
    return item.name;
  }

  async getItemByUserId(uid: string) {
    const itemRef = collection(db, `items_${process.env.VUE_APP_ENV}`);
    const q = query(itemRef, where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    const item = querySnapshot.docs.map((doc) => doc.data())[0];
    store.commit("SET_ITEM", item);
    return item;
  }

  async getItemById(id: string) {
    const itemRef = collection(db, `items_${process.env.VUE_APP_ENV}`);
    const q = query(itemRef, where("id", "==", id));
    const querySnapshot = await getDocs(q);
    const item = querySnapshot.docs.map((doc) => doc.data())[0];
    store.commit("SET_ITEM", item);
    return item;
  }

  async updateItem(item: any) {
    store.commit("SET_ITEM", item);
    const itemRef = doc(db, `items_${process.env.VUE_APP_ENV}`, item.id);
    await updateDoc(itemRef, item);
    const itemsRef = collection(db, `items_${process.env.VUE_APP_ENV}`);
    const querySnapshot = await getDocs(itemsRef);
    const items = querySnapshot.docs.map((doc) => doc.data());
    store.commit("SET_ALL_ITEMS", items);
    return items as IItem[];
  }

  async deleteItemsByUserId(userId: string) {
    const itemsRef = collection(db, "items");
    const q = query(itemsRef, where("owner", "==", userId));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
      return `Document in items deleted with ID: ${doc.id}`;
    });
    this.getAllItems();
  }

  // most rented items

  async fetchTopViewItem() {
    const itemsRef = collection(db, "viewed_items");
    const q = query(itemsRef, orderBy("views", "desc"), limit(10));

    try {
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => doc.data());

      await this.getAllItems();

      const itemWithDescription = data.map((item) => {
        return {
          ...item,
          item: store.state.allItems.find((i: IItem) => i.id === item.id),
        };
      });
      return itemWithDescription;
    } catch (error) {
      console.error("Error fetching top viewed items:", error);
    }
  }

  async mostViewedItems() {
    const viewedItemsRef = collection(db, "viewed_items");
    const querySnapshot = await getDocs(viewedItemsRef);
    const items = querySnapshot.docs
      .map((doc) => doc.data())
      .map((item) => {
        return {
          ...item,
        };
      })
      .sort((a, b) => b.views - a.views)
      .slice(0, 3);
    return items;
  }

  async incrementViewedItem(item: any) {
    const itemRef = doc(db, "viewed_items", item.id);
    // Use setDoc with merge: true to create or update the document
    await setDoc(
      itemRef,
      {
        id: item.id,
        name: item.name,
        views: increment(1),
      },
      { merge: true }
    );
  }

  async reportItem(reportedItem: any) {
    // TODO: Check if has report item been added?
    const itemRef = doc(db, "reportedItems", reportedItem.id);
    await setDoc(itemRef, reportedItem, { merge: true });
  }

  async deleteImages(itemId: string) {
    const storage = getStorage();
    const storageRef = ref(storage, "images/" + itemId);
    const images = await listAll(storageRef);
    const deletePromises = images.items.map(
      async (imageRef) => await deleteObject(imageRef)
    );
    // Wait for all deletions to complete
    await Promise.all(deletePromises);

    return "Images deleted successfully from Storage";
  }

  async deleteItem(id: string) {
    const itemRef = doc(db, `items_${process.env.VUE_APP_ENV}`, id);
    await deleteDoc(itemRef);
    return await this.getAllItems();
  }
}
